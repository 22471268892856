import { NAMESPACES } from 'constants/locales'

import React, { Fragment, memo, useCallback } from 'react'
import Headline, {
  SIZE as HEADLINE_SIZE,
} from '@typeform/ginger/dist/components/typography/headline/next'
import useTranslation from 'next-translate/useTranslation'
import { getStaticAsset } from 'utils/assets'
import { routes } from 'utils/routes'
import { trackExperimentEvent, trackRedirectToPage } from 'components/tracking'
import Markdown from 'components/atoms/markdown'

import TrackingLocation, {
  TrackingLocationNames,
} from '../common/tracking-location'
import { HOMEPAGE_REFRESH_TEST_ID } from '../constants'

import {
  CtaContainer,
  Logo,
  IntegrationsListItem,
  IntegrationsList,
  Button,
  IntegrationsListContainer,
  IntegrationsContainer,
  Br,
} from './integrations-section.styles'

const ASSETS_PATH = '/homepage-refresh/integrations'

const integrations = [
  {
    name: 'Zapier',
    logo: getStaticAsset(`${ASSETS_PATH}/zapier-logo.svg`),
  },
  {
    name: 'Google Analytics',
    logo: getStaticAsset(`${ASSETS_PATH}/analytics-logo.svg`),
  },
  {
    name: 'Hubspot',
    logo: getStaticAsset(`${ASSETS_PATH}/hubspot-logo.svg`),
  },
  {
    name: 'Slack',
    logo: getStaticAsset(`${ASSETS_PATH}/slack-logo.svg`),
  },
  {
    name: 'Calendly',
    logo: getStaticAsset(`${ASSETS_PATH}/calendly-logo.svg`),
  },
]

const IntegrationsSection = () => {
  const { t } = useTranslation(NAMESPACES.HOME)

  const handleBrowseIntegrationsClick = useCallback(() => {
    trackExperimentEvent({
      testId: HOMEPAGE_REFRESH_TEST_ID,
      name: 'homepage_integrations_section_cta_click',
    })
    trackRedirectToPage({
      label: t('homepage.integrations.cta.label'),
      linkUrl: routes().connect.path,
      location: TrackingLocationNames.Integrations,
    })
  }, [t])

  return (
    <TrackingLocation name={TrackingLocationNames.Integrations}>
      <IntegrationsContainer data-testid='integrations-section'>
        <CtaContainer>
          <Headline
            element='h2'
            largeViewportsSize={HEADLINE_SIZE.ONE}
            mediumViewportsSize={HEADLINE_SIZE.ZERO}
            smallViewportsSize={HEADLINE_SIZE.ZERO}
            extraSmallViewportsSize={HEADLINE_SIZE.ZERO}
          >
            {/* @ts-expect-error br is not defined in the components prop properly */}
            <Markdown components={{ p: Fragment, br: Br }}>
              {t('homepage.refresh.integrations.title')}
            </Markdown>
          </Headline>
          <Button
            href={routes().connect.path}
            onClick={handleBrowseIntegrationsClick}
          >
            {t('homepage.refresh.integrations.cta.label')}
          </Button>
        </CtaContainer>
        <IntegrationsListContainer>
          <IntegrationsList>
            {integrations.map(({ name, logo }) => (
              <IntegrationsListItem key={name}>
                <Logo
                  alt={name}
                  src={logo}
                  loading='lazy'
                  decoding='async'
                  // @ts-expect-error fetchpriority is not yet defined in HTMLLinkElement
                  fetchpriority='low'
                />
              </IntegrationsListItem>
            ))}
          </IntegrationsList>
        </IntegrationsListContainer>
      </IntegrationsContainer>
    </TrackingLocation>
  )
}

IntegrationsSection.displayName = 'IntegrationsSection'

export default memo(IntegrationsSection)
